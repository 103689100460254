@import "mixins/common";
@import "mixins/navigation";
@import "mixins/context-menu";

.dxbl-context-menu,
.dxbl-context-menu-submenu {
    --dxbl-context-menu-font-family: #{$dx-context-menu-font-family};
    --dxbl-context-menu-color: #{$dx-context-menu-color};
    --dxbl-context-menu-bg: #{$dx-context-menu-bg};
    --dxbl-context-menu-font-size: #{$dx-context-menu-font-size};
    --dxbl-context-menu-line-height: #{$dx-context-menu-line-height};
    --dxbl-context-menu-item-text-padding-x: #{$dx-context-menu-item-text-padding-x};
    --dxbl-context-menu-item-padding-x: #{$dx-context-menu-item-padding-x};
    --dxbl-context-menu-item-padding-y: #{$dx-context-menu-item-padding-y};
    --dxbl-context-menu-min-width: #{$dx-context-menu-min-width};
    --dxbl-context-menu-item-popout-icon-opacity: #{$dx-context-menu-item-popout-icon-opacity};
    --dxbl-context-menu-disabled-bg: #{$dx-context-menu-disabled-bg};
    --dxbl-context-menu-disabled-color: #{$dx-context-menu-disabled-color};
    --dxbl-context-menu-disabled-opacity: #{$dx-context-menu-disabled-opacity};
    --dxbl-context-menu-item-hover-color: #{$dx-context-menu-item-hover-color};
    --dxbl-context-menu-item-hover-bg: #{$dx-context-menu-item-hover-bg};
    --dxbl-context-menu-item-active-bg: #{$dx-context-menu-item-active-bg};
    --dxbl-context-menu-item-active-color: #{$dx-context-menu-item-active-color};
    --dxbl-context-menu-separator-width: #{$dx-context-menu-separator-width};
    --dxbl-context-menu-separator-color: #{$dx-context-menu-separator-color};
    --dxbl-context-menu-separator-style: #{$dx-context-menu-separator-style};
    --dxbl-context-menu-border-radius: #{$dx-context-menu-border-radius};
    --dxbl-context-menu-border-width: #{$dx-context-menu-border-width};
    --dxbl-context-menu-border-color: #{$dx-context-menu-border-color};
    --dxbl-context-menu-border-style: #{$dx-context-menu-border-style};
    --dxbl-context-menu-box-shadow: #{$dx-dropdown-box-shadow};
    --dxbl-context-menu-padding-x: #{$dx-context-menu-padding-x};
    --dxbl-context-menu-padding-y: #{$dx-context-menu-padding-y};
    --dxbl-context-menu-image-spacing: #{$dx-context-menu-image-spacing};

    &.dxbl-sm {
        --dxbl-context-menu-font-size: #{$dx-context-menu-font-size-sm};
        --dxbl-context-menu-line-height: #{$dx-context-menu-line-height-sm};
        --dxbl-context-menu-item-text-padding-x: #{$dx-context-menu-item-text-padding-x-sm};
        --dxbl-context-menu-item-padding-x: #{$dx-context-menu-item-padding-x-sm};
        --dxbl-context-menu-item-padding-y: #{$dx-context-menu-item-padding-y-sm};
        --dxbl-context-menu-min-width: #{$dx-context-menu-min-width-sm};
        --dxbl-context-menu-image-spacing: #{$dx-context-menu-image-spacing-sm};
    }

    &.dxbl-lg {
        --dxbl-context-menu-font-size: #{$dx-context-menu-font-size-lg};
        --dxbl-context-menu-line-height: #{$dx-context-menu-line-height-lg};
        --dxbl-context-menu-item-text-padding-x: #{$dx-context-menu-item-text-padding-x-lg};
        --dxbl-context-menu-item-padding-x: #{$dx-context-menu-item-padding-x-lg};
        --dxbl-context-menu-item-padding-y: #{$dx-context-menu-item-padding-y-lg};
        --dxbl-context-menu-min-width: #{$dx-context-menu-min-width-lg};
        --dxbl-context-menu-image-spacing: #{$dx-context-menu-image-spacing-lg};
    }

    &.dxbl-context-menu-dropdown {
        --dxbl-dropdown-font-family: var(--dxbl-context-menu-font-family);
        --dxbl-dropdown-font-size: var(--dxbl-context-menu-font-size);
        --dxbl-dropdown-color: var(--dxbl-context-menu-color);
        --dxbl-dropdown-line-heigth: var(--dxbl-context-menu-line-height);
        --dxbl-dropdown-bg: var(--dxbl-context-menu-bg);
        --dxbl-dropdown-body-padding-x: var(--dxbl-context-menu-padding-x);
        --dxbl-dropdown-body-padding-y: var(--dxbl-context-menu-padding-y);
        --dxbl-dropdown-border-radius: var(--dxbl-context-menu-border-radius);
        --dxbl-dropdown-border-width: var(--dxbl-context-menu-border-width);
        --dxbl-dropdown-border-color: var(--dxbl-context-menu-border-color);
        --dxbl-dropdown-border-style: var(--dxbl-context-menu-border-style);
        --dxbl-dropdown-min-width: var(--dxbl-context-menu-min-width);
        --dxbl-dropdown-box-shadow: var(--dxbl-context-menu-box-shadow);

        > .dxbl-dropdown-body {
            > ul {
                margin: 0;
                padding-left: 0;
                list-style: none;
                width: 100%;

                &.dxbl-context-menu-item-list-with-icons > li > .dxbl-context-menu-item {
                    &.dxbl-context-menu-item-with-icon {
                        .dxbl-context-menu-item-text {
                            padding-left: var(--dxbl-context-menu-image-spacing);
                        }
                    }

                    .dxbl-context-menu-item-text {
                        display: inline-block;
                        padding-left: var(--dxbl-context-menu-item-text-padding-x);
                    }
                }

                li.dxbl-context-menu-separator {
                    @include menu-items-separator("--dxbl-context-menu-separator-width", "--dxbl-context-menu-separator-style", "--dxbl-context-menu-separator-color");
                }

                li:not(.dxbl-context-menu-separator) {
                    &:first-child > .dxbl-context-menu-item {
                        border-top-left-radius: var(--dxbl-context-menu-border-radius);
                        border-top-right-radius: var(--dxbl-context-menu-border-radius);
                    }

                    &:last-child > .dxbl-context-menu-item {
                        border-bottom-left-radius: var(--dxbl-context-menu-border-radius);
                        border-bottom-right-radius: var(--dxbl-context-menu-border-radius);
                    }

                    & > .dxbl-context-menu-item {
                        display: flex;
                        align-items: center;
                        position: relative;
                        width: 100%;
                        cursor: pointer;
                        white-space: nowrap;
                        padding: var(--dxbl-context-menu-item-padding-y) var(--dxbl-context-menu-item-padding-x) var(--dxbl-context-menu-item-padding-y) var(--dxbl-context-menu-item-padding-x);

                        &:not(.dxbl-disabled):not(.dxbl-active):hover {
                            @include navigation-item-hover-state("--dxbl-context-menu-item-hover-bg", "--dxbl-context-menu-item-hover-color");
                        }

                        .dxbl-context-menu-item-text {
                            margin-right: auto;
                        }

                        .dxbl-context-menu-item-popout-icon {
                            margin-left: var(--dxbl-context-menu-image-spacing);
                        }

                        .dxbl-context-menu-item-icon {
                            padding: 0;
                            display: inline-flex;
                        }

                        @include disabled-state() {
                            @include state-disabled-style("--dxbl-context-menu-disabled-bg", "--dxbl-context-menu-disabled-color");

                            opacity: var(--dxbl-context-menu-disabled-opacity);
                            cursor: default;
                        }

                        @include not-disabled-state() {
                            &:active {
                                @include menu-item-pressed-state("--dxbl-context-menu-item-active-bg", "--dxbl-context-menu-item-active-color");
                            }

                            .dxbl-context-menu-item-popout-icon {
                                opacity: var(--dxbl-context-menu-item-popout-icon-opacity);
                            }
                        }
                    }
                }
            }
        }
    }
}
