.dxbs-context-menu,
.dxbs-context-menu-submenu {
    &.dxbl-dropdown-dialog.dxbs-context-menu-dropdown {
        min-width: 10rem;
        padding: 0;

        > .dxbl-dropdown-body {
            padding: 0;

            > ul {
                margin: 0;
                width: 100%;
            }

            .dropdown-divider {
                margin: 0;

                &:first-child {
                    display: none;
                }
            }
        }
    }
}

.dxbs-context-menu-item.dropdown-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-left: 8px;

    .has-icons > li > & {
        .dxbs-context-menu-item-text {
            display: inline-block;
            padding-left: 24px;
        }

        &.has-icon .dxbs-context-menu-item-text {
            padding-left: 8px;
        }
    }

    .dx-popout-image {
        position: absolute;
        right: 0.25rem;
    }

    .dxbs-context-menu-item-icon {
        padding: 0;
        display: inline-flex;
    }

    &:not(.disabled) {
        .dx-popout-image {
            opacity: 0.7;
        }
    }
}

