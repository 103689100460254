@import "mixins/context-menu";
@import "mixins/menu";

.dxbl-menu.dxbl-menu-vertical {
    --dxbl-menu-item-font-size: #{$dx-menu-vertical-item-font-size};
    --dxbl-menu-item-line-height: #{$dx-menu-vertical-item-line-height};
    --dxbl-menu-item-padding-x: #{$dx-menu-vertical-item-padding-x};
    --dxbl-menu-item-padding-y: #{$dx-menu-vertical-item-padding-y};
    --dxbl-menu-title-padding-x: #{$dx-menu-vertical-item-padding-x};
    --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y};
    --dxbl-menu-item-image-spacing: #{$dx-menu-vertical-item-image-spacing};

    &.dxbl-sm {
        --dxbl-menu-item-font-size: #{$dx-menu-vertical-item-font-size-sm};
        --dxbl-menu-item-line-height: #{$dx-menu-vertical-item-line-height-sm};
        --dxbl-menu-item-padding-x: #{$dx-menu-vertical-item-padding-x-sm};
        --dxbl-menu-item-padding-y: #{$dx-menu-vertical-item-padding-y-sm};
        --dxbl-menu-title-padding-x: #{$dx-menu-vertical-item-padding-x-sm};
        --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y-sm};
        --dxbl-menu-item-image-spacing: #{$dx-menu-vertical-item-image-spacing-sm};
    }

    &.dxbl-lg {
        --dxbl-menu-item-font-size: #{$dx-menu-vertical-item-font-size-lg};
        --dxbl-menu-item-line-height: #{$dx-menu-vertical-item-line-height-lg};
        --dxbl-menu-item-padding-x: #{$dx-menu-vertical-item-padding-x-lg};
        --dxbl-menu-item-padding-y: #{$dx-menu-vertical-item-padding-y-lg};
        --dxbl-menu-title-padding-x: #{$dx-menu-vertical-item-padding-x-lg};
        --dxbl-menu-title-padding-y: #{$dx-menu-title-padding-y-lg};
        --dxbl-menu-item-image-spacing: #{$dx-menu-vertical-item-image-spacing-lg};
    }

    & > .dxbl-menu-bar {
        flex-direction: column;

        & .dxbl-menu-title {
            align-self: start;
        }
    }

    .dxbl-menu-title {
        padding: var(--dxbl-menu-title-padding-y) var(--dxbl-menu-title-padding-x);
    }

    .dxbl-menu-item-list {
        flex-direction: column;
        width: 100%;
        height: 100%;

        &:not(.dxbl-menu-with-title) {
            & > .dxbl-menu-list-item:first-child > .dxbl-menu-item > .dxbl-btn {
                border-top-left-radius: var(--dxbl-menu-top-left-border-radius);
                border-top-right-radius: var(--dxbl-menu-top-right-border-radius);
            }
        }

        &.dxbl-menu-item-list-with-icons > .dxbl-menu-list-item > .dxbl-menu-item > .dxbl-btn {
            & > .dxbl-menu-item-text-container:first-child {
                @include menu-item-image-placeholder("margin-left", "--dxbl-menu-item-image-size", "--dxbl-menu-item-image-spacing");
            }
        }

        & > .dxbl-menu-separator {
            @include menu-items-separator("--dxbl-menu-separator-width", "--dxbl-menu-separator-style", "--dxbl-menu-separator-color", true);
        }

        & > .dxbl-menu-list-item {
            &:last-child > .dxbl-menu-item > .dxbl-btn {
                border-bottom-left-radius: var(--dxbl-menu-bottom-left-border-radius);
                border-bottom-right-radius: var(--dxbl-menu-bottom-right-border-radius);
            }

            &.dxbl-menu-list-item-stretch > .dxbl-btn {
                height: 100%;
            }
        }
    }
}
