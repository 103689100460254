$dx-primary: #5f368d !default;
$dx-success: #28a745 !default;
$dx-info: #00a9e6 !default;
$dx-danger: #dc3545 !default;

$dx-gray-100: #ececec !default;
$dx-gray-200: #e9ecef !default;
$dx-gray-300: #dedede !default;
$dx-gray-400: #ced4da !default;
$dx-gray-500: #adb5bd !default;
$dx-gray-600: #888888 !default;
$dx-gray-700: #495057 !default;
$dx-gray-800: #343a40 !default;
$dx-gray-900: #212529 !default;
$dx-black:    #222222 !default;

$dx-min-contrast-ratio: 1.8 !default;

// Common
$dx-color: $dx-gray-900 !default;
$dx-border-color: #d2d2d2 !default;

// Button
$dx-btn-special-box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 20%) !default;

// Grid
$dx-grid-inline-edit-focus-border-opacity: 0.75 !default;

